import React from "react";

const Services = () => {
  // services details
  const services = [
    {
      name: "Comprehensive Data Strategy Development",
      desc: "Craft tailored data strategies aligned with business objectives, leveraging my expertise to align technology with organizational goals.",
      icon: "fas fa-palette",
    },
    {
      name: "Advanced Data Modeling and Architecture",
      desc: "Design sophisticated and scalable data models and architectures, ensuring optimal performance and flexibility for evolving business needs.",
      icon: "fas fa-desktop",
    },
    {
      name: "Cloud Migration and Optimization",
      desc: "Seamlessly migrate data to cloud platforms, optimizing infrastructure for cost-efficiency, scalability, and enhanced performance.",
      icon: "fas fa-pencil-ruler",
    },
    {
      name: "Real-time Data Processing",
      desc: "Implement cutting-edge solutions for real-time data processing, enabling organizations to extract insights and make informed decisions instantaneously.",
      icon: "fas fa-paint-brush",
    },
    {
      name: "Data Governance and Compliance",
      desc: "Establish robust data governance frameworks, ensuring data integrity, security, and compliance with industry regulations and best practices.",
      icon: "fas fa-chart-area",
    },
    {
      name: "Predictive Analytics and Machine Learning Integration",
      desc: "Harness the power of predictive analytics and machine learning, incorporating advanced algorithms to extract valuable insights, drive innovation, and stay ahead in the competitive landscape.",
      icon: "fas fa-bullhorn",
    },
  ];

  return (
    <section id="services" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">What I Do?</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          How I can help your next project
        </h2>
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {" "}
                    <i className={service.icon} />
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="text-muted mb-0">{service.desc} </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
