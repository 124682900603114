import React from "react";
import resumeFile from "../documents/resume.pdf";

const Resume = () => {
  const educationDetails = [
    {
      yearRange: "2018 - 2020",
      title: "Master in Science of Analytics",
      place: "Universidad de los Andes, Bogotá, Col",
      desc: "Empowered by the MSs of Analytics, I possess advanced skills in data analysis, statistical modeling, and machine learning. This program has equipped me to extract valuable insights, drive informed decision-making, and innovate across diverse professional domains such as business intelligence, finance, healthcare, and technology. I bring a strategic and data-driven perspective to tackle complex challenges and optimize performance in today's dynamic and competitive landscape.",
    },
    {
      yearRange: "2006 - 2012",
      title: "Bachelor in Electronic Engineering",
      place: "University del Norte, Barranquilla, Col",
      desc: " I've cultivated a strong foundation in programming languages and problem-solving. My expertise spans diverse programming languages, enabling me to engineer innovative solutions in areas such as embedded systems, automation, and telecommunications. This comprehensive background equips me to excel in roles that demand a blend of electronic engineering skills and programming proficiency, fostering technological advancements and efficiency in various professional domains.",
    },
    {
      yearRange: "2022 - 2023",
      title: "Terrarom certified",
      place: "HCL University",
      desc: "As a Data Architect with Terraform certification, I specialize in designing and optimizing data infrastructures with a focus on scalability, security, and efficiency. My expertise extends from crafting robust data architectures to leveraging Terraform for seamless infrastructure as code implementation. This certification underscores my commitment to deploying cutting-edge, automated solutions that enhance data management and drive organizational success.",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "2021 - Current",
      title: "Machine Learning Enngineer",
      place: "HBO Max - Contractor",
      desc: "Worked close with the Data Scientists and MLE from the Content Personalization HBOMax Team in the recommendation platform, helping across all the recommendations models through the data. Create and maintenance data layer for core machine learning models. Create workflows in Databricks to process data through snowflake and dbx catalogs.",
    },
    {
      yearRange: "2020 - 2021",
      title: "Lead DataOps/MLOps - SRE",
      place: "Aval Digital Labs, Colombia",
      desc: "Lead the SRE DevOps team with high focus on data solution. Guarantee the continuous integration/ continuous delivery of Advanced Analytics Solutions. Infrastructure provisioner of data platform with IaC workflow. Build solutions in house to reduce the data operations and ticket resolutions. Implemented a Data Lake solution. Implement DataBricks, H2O, Denodo as DS tools services. Deploy Jenkins solution using Groovy, Ansible and Terraform for ML team.",
    },
    {
      yearRange: "2019 - 2020",
      title: "Sr. Data Engineer",
      place: "Aval Digital Labs, Colombia",
      desc: "Enabled data ingest to cloud computing services from on-premises. Build Data Pipelines to ingest TB of Data from Banking entities. Evangelization of Data Cloud culture on Banking entities. Approaching Marquez as Data Lineage solution for Data Engineer team. Approaching Great Expectations for data validations.",
    },
    {
      yearRange: "2015 - 2019",
      title: "Sr. Busines Intelligence",
      place: "Telefónica Movistar - Colombia",
      desc: "Ensured impeccable data quality within the Data Warehouse and Big Data ecosystem, implementing rigorous measures to validate and enhance data integrity. Spearheaded the construction of Big Data extractions from HDFS, optimizing data retrieval processes. Additionally, designed and implemented internal dashboards, providing real-time insights to monitor and ensure the robust health of our data systems. This comprehensive role showcased my commitment to maintaining high-quality data standards while leveraging advanced technologies for efficient data management and system monitoring.",
    },
    {
      yearRange: "2012 - 2015",
      title: "Product Lead",
      place: "Tecnosystems by Tecnoglass",
      desc: "Coordinated projects in web application solutions. Played a pivotal role as a Database Administrator, ensuring the seamless integration of robust data layers. My responsibilities extended to understanding and translating customers' requirements, facilitating effective communication between technical teams and clients. This multifaceted experience not only showcased my project coordination skills but also demonstrated my proficiency in database management and client-centric problem-solving.",
    },
    {
      yearRange: "2010 - 2012",
      title: "Mobile Developer",
      place: "D-tech",
      desc: "As mobile developer, I pioneered the creation of dynamic survey applications, seamlessly collecting valuable data. Utilizing my expertise, I consolidated and analyzed this information, transforming raw data into actionable insights. This process not only empowered informed decision-making but also triggered strategic actions, highlighting my ability to bridge mobile development with data-driven strategies for impactful outcomes",
    },
  ];

  const skills = [
    {
      name: "Hadoop",
      percent: 70,
    },
    {
      name: "Spark/Spark SQL/PySpark",
      percent: 80,
    },
    {
      name: "Python",
      percent: 90,
    },
    {
      name: "Machine Learning",
      percent: 70,
    },
    {
      name: "Amazon Cloud Services",
      percent: 90,
    },
    {
      name: "Terraform",
      percent: 95,
    },
    {
      name: "Streaming",
      percent: 65,
    },
  ];

  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Resume</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          A summary of My Resume
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Education */}
          <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Education</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
          {/* My Experience */}
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Experience</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* My Skills */}
        <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">My Skills</h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div className="col-md-6 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2">
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div className="progress progress-sm mb-4">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <p className="text-center mt-5 wow fadeInUp">
          <a
            className="btn btn-outline-dark shadow-none rounded-0"
            href={resumeFile}
            download
          >
            Download CV
          </a>
        </p>
      </div>
    </section>
  );
};

export default Resume;
